import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import auLocale from 'dayjs/locale/en-au'

dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.locale({ ...auLocale });


export const getSydneyDate = (date: Date | number | string = new Date()) => {
  return dayjs(date).tz(TIMEZONES.SYDNEY);
}
export enum TIMEZONES {
  SYDNEY = 'Australia/Sydney'
}

export const durationToSeconds = (duration: string): number | undefined => {
  const matches = duration.match(/(\d*h)?\s?(\d*m)?/);
  if (matches) {
    const hours = matches[1];
    const minutes = matches[2];
    let seconds = 0;
    if (hours) {
      seconds = seconds + parseInt(hours.replace('h', ''), 10) * 3600;
    }
    if (minutes) {
      seconds = seconds + parseInt(minutes.replace('m', ''), 10) * 60;
    }
    return seconds;
  }
  return undefined
}